@import "../../variables.scss";

.footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  &-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin: 4rem 2rem 2rem;

    @media screen and (max-width: 450px) {
      margin: 2rem 2rem 1rem;
      width: 90%;
    }
  }

  &-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: $primary-color;
    transition: all 0.3 ease-in-out;
    
    .icon {
      width: 35px;
      height: 35px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 25px $primary-color;
    }

    @media screen and (max-width: 450px) {
      margin: 0.6rem 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;

    @media screen and (max-width: 450px) {
      width: 90%;
    }

    div {
      width: 100%;
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: $primary-color;
      transition: all 0.3s ease-in-out;

      input, textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: $primary-color;

        font-family: $font-base;
        color: $secondary-color;
        outline: none;
      }

      textarea {
        height: 170px;

        &:hover {
          box-shadow: 0 0 25px $primary-color;
        }
      }
    
      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
      }

      @media screen and (max-width: 450px) {
        margin: 0 0 1.2rem 0;
      }
    }

    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: $secondary-color;

      font-weight: 500;
      outline: none;
      margin: 2rem 0 0 0;
      color: $white-color;
      font-family: $font-base;
      cursor: pointer;

      &:hover {
        background-color: $hover-color;
      }

      @media screen and (max-width: 450px) {
        margin: 1rem 0 0 0;
      }
    }
  }
}