@import "../../variables.scss";

.about {
  flex: 1;
  width: 100%;
  flex-direction: column;

  h2 {
    color: $secondary-color;
  }
}

.about-container {
  width: 80%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.about-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;  
  flex: 1;

  margin-right: 5rem;

  h3 {
    font-size: 1.6rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 450px) {
      text-align: center;
      line-height: 110%;
    }
  }
  
  p {
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    

    @media screen and (max-width: 450px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
  }
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
    
    img {
      height: 320px;
    }
  }
}

.about-social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    display: none;
  }

  div {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $white-color;
    margin: 0.25rem 0;
    border: 1px solid $lightGray-color;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;
  
    svg {
      width: 25px;
      height: 25px;
      color: $gray-color;
    }
     
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;

      svg {
        color: $white-color;
      }
    }

  }

}

span {
  color: $secondary-color;
} 

.head-text {
  margin-top: 2rem;
} 
