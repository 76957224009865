$font-base: 'Montserrat', sans-serif;

$primary-color: #edf2f8;
$hover-color: #E32967;
$secondary-color: #E95687;
$dots-color: #E95687;

$black-color: #030303;
$lightGray-color: #e4e4e4;
$gray-color: #6b7688;
$brown-color: #46364a;
$white-color: rgb(255,255,255);
$card-color: rgba(254, 228, 237, 0.5);
$card-color-2: #f2f7fb;
