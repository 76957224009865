@import './variables.scss';

.app {
  font-family: $font-base;
}

.container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: $black-color;
  text-transform: capitalize;

  span {
    color: $secondary-color;
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: $gray-color;
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: $black-color;
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  padding: 1rem;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $white-color;
    margin: 0.25rem 0;
    border: 1px solid $lightGray-color;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;
  
    svg {
      width: 15px;
      height: 15px;
      color: $gray-color;
    }
     
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;

      svg {
        color: $white-color;
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      a svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  z-index: 1;

  .navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $dots-color;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $secondary-color;
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .navigation,
  .social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

@-webkit-keyframes highlight {
    to {
      background-position: 0 0;
    }
  }
  
    @keyframes highlight {
    to {
      background-position: 0 0;
    }
  }
  