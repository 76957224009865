@import "../../variables.scss";

#home {
  position: relative;
  background: $white-color;

  .wrapper {
    padding: 0;
  }

  @media screen and (max-width:480px) {
    background: url(../../assets/profile.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.text-md {
  @media screen and (min-width: 821px) {
    display: none;
  } 
}

.header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (max-width: 480px) {
    padding: 1rem 1rem 2rem;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  &-info {
    z-index: 1;
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }

    @media screen and (max-width: 480px) {
      justify-content: flex-end;
      flex: 1;
      margin-bottom: 2rem;
    }
  }

  &-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10rem;

    @media screen and (max-width: 1024px){
      margin-top: 5rem;
    }

    h1 {
      text-align: left;
      font-size: 3.5rem;
      text-transform: none;

      @media screen and (max-width: 480px) {
        font-size: 2rem;
      } 

      @media screen and (max-width: 1024px) {
        font-size: 2.5rem;
      } 

      &:first-child {
        @media screen and (max-width: 820px) {
        display: none;
      }
      
      &:nth-child(2) {
        @media screen and (min-width: 821px) {
          display: none;
        }  
      }  
    }
  }

    h2 {
      margin-top: 1.5rem;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      color: $gray-color;
      line-height: 1.5;
          
      @media screen and (max-width: 1024px){
        margin-top: 1rem;
      }  

      @media screen and (min-width: 2000px) {
        font-size: 2rem;
      }   
    }

    h1, h2 {
      @media screen and (max-width: 820px){
        color: $white-color;
        padding: 0 0;
      }
    } 
  }
  
  &-img {
    flex: 1;
    height: 100%;
  
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
  
    img {   
      width: 100%;
      max-height: 97vh;
      object-fit: contain;
      position: absolute;
      bottom: 0;
    }
    
    @media screen and (max-width:480px){
      display: none;    
    }
 
    @media screen and (max-width: 820px) {
      height: 100vh;
      justify-content: center;
      align-items: flex-end;
      position: static;    
    }

    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
  }

  &-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1.5rem 0 0 0;

    a {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: $secondary-color;
      text-decoration: none;

      font-weight: 500;
      outline: none;
      color: $white-color;
      font-family: $font-base;
      cursor: pointer;

      &:first-child {
        margin-right: 2rem;

        @media screen and (max-width: 1024px){
          margin-right: 1rem;
        } 
      }
      
      &:hover {
        background-color: $hover-color;
      }
      
      @media screen and (max-width: 1024px){
        padding: 0.8rem 1.5rem;
        text-align: center;
      }  

      @media screen and (max-width: 820px){
        background-color: $secondary-color;
      } 
    }

    @media screen and (max-width: 1024px){
      margin: 1rem 0 0 0;
    } 
    
  }
}



@media screen and (max-width: 820px) and (min-width: 481px) {
  
  .header-title {
    h1, h2 { 
      -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
      animation: 1.5s highlight 1.5s 1 normal forwards;
      background-color: none;
      background: linear-gradient(90deg, $hover-color  50%, rgba(255, 255, 255, 0) 50%);
      background-size: 200% 100%;
      background-position: 100% 0;
    }  

    h2 {
      width: fit-content;
    }
  }
}

@-webkit-keyframes highlight {
  to {
    background-position: 0 0;
  }
}

@keyframes highlight {
  to {
    background-position: 0 0;
  }
}





// @import "../../variables.scss";

// #home {
//   position: relative;
//   background: $white-color;

//   .wrapper {
//     padding: 0;
//   }

//   @media screen and (max-width:480px) {
//     background: url(../../assets/profile.jpg);
//     background-size: cover;
//     background-position: center;
//     background-repeat: no-repeat;
//   }
// }

// .text-md {
//   @media screen and (min-width: 769px) {
//     display: none;
//   } 
// }

// .header {
//   flex: 1;
//   width: 100%;
//   height: 100%;
//   flex-direction: row;

//   padding: 6rem 2rem 0rem;

//   @media screen and (max-width: 480px) {
//     padding: 1rem 1rem 2rem;
//   }

//   @media screen and (max-width: 768px) {
//     flex-direction: column;
//   }

//   @media screen and (min-width: 2000px) {
//     padding-top: 8rem;
//   }

//   &-info {
//     z-index: 1;
//     flex: 0.65;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     height: 100%;
//     margin: 0 2rem;
  
//     @media screen and (max-width: 1200px) {
//       width: 100%;
//       margin-right: 0rem;
//     }

//     @media screen and (max-width:480px) {
//       justify-content: flex-end;
//       flex: 1;
//       margin-bottom: 2rem;
//     }
//   }

//   &-title {
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//     align-items: flex-start;
//     flex-direction: column;
//     margin-top: 10rem;

//     @media screen and (max-width: 1024px){
//       margin-top: 5rem;
//     }

//     h1 {
//       text-align: left;
//       font-size: 3.5rem;
//       text-transform: none;

//       @media screen and (max-width: 480px) {
//         font-size: 2rem;
//       } 

//       @media screen and (max-width: 1024px) {
//         font-size: 2.5rem;
//       } 

//       &:first-child {
//         @media screen and (max-width: 768px) {
//         display: none;
//       }
      
//       &:nth-child(2) {
//         @media screen and (min-width: 769px) {
//           display: none;
//         }  
//       }  
//     }
//   }

//     h2 {
//       margin-top: 1.5rem;
//       width: 100%;
//       font-size: 1rem;
//       font-weight: 400;
//       color: $gray-color;
//       line-height: 1.5;
          
//       @media screen and (max-width: 1024px){
//         margin-top: 1rem;
//       }  

//       @media screen and (min-width: 2000px) {
//         font-size: 2rem;
//       }   
//     }

//     h1, h2 {
//       @media screen and (max-width: 768px){
//         color: $white-color;
//         padding: 0 0;
//       }
//     } 
//   }
  
//   &-img {
//     flex: 1;
//     height: 100%;
  
//     display: flex;
//     justify-content: flex-end;
//     align-items: flex-end;
//     position: relative;
  
//     img {   
//       width: 100%;
//       max-height: 97vh;
//       object-fit: contain;
//       position: absolute;
//       bottom: 0;
//     }
    
//     @media screen and (max-width:480px){
//       display: none;    
//     }
 
//     @media screen and (max-width: 768px) {
//       height: 100vh;
//       justify-content: center;
//       align-items: flex-end;
//       position: static;    
//     }

//     @media screen and (max-width: 1200px) {
//       margin: 2rem 0;
//     }
//   }

//   &-links {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     margin: 1.5rem 0 0 0;

//     a {
//       padding: 1rem 2rem;
//       border-radius: 10px;
//       border: none;
//       background-color: $secondary-color;
//       text-decoration: none;

//       font-weight: 500;
//       outline: none;
//       color: $white-color;
//       font-family: $font-base;
//       cursor: pointer;

//       &:first-child {
//         margin-right: 2rem;

//         @media screen and (max-width: 1024px){
//           margin-right: 1rem;
//         } 
//       }
      
//       &:hover {
//         background-color: $hover-color;
//       }
      
//       @media screen and (max-width: 1024px){
//         padding: 0.8rem 1.5rem;
//         text-align: center;
//       }  

//       @media screen and (max-width: 768px){
//         background-color: $secondary-color;
//       } 
//     }

//     @media screen and (max-width: 1024px){
//       margin: 1rem 0 0 0;
//     } 
    
//   }
// }



// @media screen and (max-width: 768px) and (min-width: 481px) {
  
//   .header-title {
//     h1, h2 { 
//       -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
//       animation: 1.5s highlight 1.5s 1 normal forwards;
//       background-color: none;
//       background: linear-gradient(90deg, $hover-color  50%, rgba(255, 255, 255, 0) 50%);
//       background-size: 200% 100%;
//       background-position: 100% 0;
//     }  

//     h2 {
//       width: fit-content;
//     }
//   }
// }

// @-webkit-keyframes highlight {
//   to {
//     background-position: 0 0;
//   }
// }

// @keyframes highlight {
//   to {
//     background-position: 0 0;
//   }
// }

